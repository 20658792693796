import { Layout } from 'antd';
import { useEffect, useState } from 'react';

import ClhRateCalculator from '@clh/react-rate-calculator';
import { unwrapError, useApiClient } from '@clh/ui';

import { useToast } from '../hooks/use-toast';
import { Content, Header } from '../layout';

import './rate-calculator-bs.css';

const RateCalculator = () => {
    const api = useApiClient();
    const [fees, setFees] = useState({
        platformFeePct: 0.07,
        employerFeePct: 0.1,
    });
    const { notification } = useToast();

    useEffect(() => {
        api
            ?.billingControllerGetFees()
            .then(setFees)
            .catch((e) =>
                notification.error({
                    message: unwrapError(e),
                    duration: 3,
                })
            );
    }, []);

    return (
        <Layout>
            <Header>
                <h2>Rate Calculator</h2>
            </Header>
            <Content>
                <div className="rate-calculator">
                    <ClhRateCalculator
                        isPlatformAdminView
                        platformFeePct={fees.platformFeePct}
                        employerFeePct={fees.employerFeePct}
                        onError={(e) =>
                            notification.error({
                                message: unwrapError(e),
                                duration: 3,
                            })
                        }
                    />
                </div>
            </Content>
        </Layout>
    );
};

export default RateCalculator;
