import { EditFilled } from '@ant-design/icons';
import {
    InputNumber,
    Button,
    Typography,
    List as AntList,
    Segmented,
    Flex,
} from 'antd';
import { capitalize } from 'lodash';
import { Interval } from 'luxon';
import { useState, PropsWithChildren, useEffect } from 'react';

import { ShiftUpdateDto, ShiftListItemDto, ShiftTime } from '@clh/api-client';
import { useApiClient } from '@clh/ui';
import {
    CalculatedCosts,
    CalculatedRates,
    calculateTotalStipend,
    CostCalculator,
    getEstimatedMinutesToBeWorkedFromShiftLength,
    getShiftDuration,
    roundToAtMostTwoDecimals,
} from '@clh/util';

import { ActionButton } from '../../action-button';
import { useSelection } from '../../hooks/use-selection';
import ObjectPropertyList from '../../record-details/object-property-list';

const pctValue = (rate: number) => (
    <>{rate ? (rate * 100).toFixed(1) : '--'}%</>
);

const usdValue = (pennies: number) => (
    <>
        $
        {(pennies / 100).toLocaleString('US', {
            currency: 'USD',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        })}
    </>
);

enum EditModeType {
    Pct,
    Money,
}

interface EditProps {
    value: number;
    type: EditModeType;
    field: keyof ShiftUpdateDto;
}

const EditMode = (
    props: EditProps & {
        close: () => void;
    }
) => {
    const [value, setValue] = useState<number | null>();
    const api = useApiClient();
    const { selection, setSelection } = useSelection<ShiftListItemDto>();

    return (
        <>
            {props.type === EditModeType.Pct && (
                <InputNumber
                    defaultValue={parseInt((props.value * 100).toFixed(1))}
                    onChange={(val) => setValue(val)}
                    min={0}
                    max={100}
                    formatter={(value) => `${value}%`}
                    parser={(value) =>
                        value?.replace('%', '') as unknown as number
                    }
                />
            )}
            {props.type === EditModeType.Money && (
                <InputNumber
                    defaultValue={props.value / 100}
                    onChange={(val) => setValue(val)}
                    formatter={(value) =>
                        `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    }
                    parser={(value) =>
                        value?.replace(/\$\s?|(,*)/g, '') as unknown as number
                    }
                />
            )}
            <ActionButton
                disabled={!value || value === props.value}
                successMessage="Saved"
                action={async () => {
                    if (!value) {
                        throw new Error('No value provided');
                    }

                    const val =
                        props.type === EditModeType.Money
                            ? value * 100
                            : props.type === EditModeType.Pct
                            ? value / 100
                            : value;

                    await api?.shiftControllerUpdateShift({
                        shiftId: selection.id,
                        shiftUpdateDto: {
                            [props.field]: val,
                        },
                    });
                    setSelection({ ...selection, [props.field]: val });
                    props.close();
                }}
            >
                Save
            </ActionButton>
            <Button onClick={props.close}>Cancel</Button>
        </>
    );
};

const Editable = (props: PropsWithChildren & EditProps) => {
    const [editMode, setEditMode] = useState<boolean>(false);

    return (
        <div>
            {editMode ? (
                <EditMode
                    value={props.value}
                    type={props.type}
                    field={props.field}
                    close={() => setEditMode(false)}
                />
            ) : (
                <>
                    {props.children}
                    <a onClick={() => setEditMode(true)}>
                        <EditFilled />
                    </a>
                </>
            )}
        </div>
    );
};

export default function CostsTab() {
    const { selection } = useSelection<ShiftListItemDto>();

    const [costs, setCosts] = useState<CalculatedCosts>();
    const [rates, setRates] = useState<CalculatedRates>();
    const [calculationBasis, setCalculationBasis] = useState<
        'actual' | 'estimate'
    >(selection.minutesWorked ? 'actual' : 'estimate');

    const scheduledMinutes =
        selection.shiftTime === ShiftTime.Custom
            ? getEstimatedMinutesToBeWorkedFromShiftLength(
                  Interval.fromDateTimes(
                      selection.startTime,
                      selection.endTime
                  ).toDuration('minutes').minutes
              )
            : getShiftDuration(selection.shiftTime) * 60;

    const stipends =
        selection.stipends && selection.stipends.length > 0
            ? selection.stipends.filter((s) => s.qualified)
            : [];

    useEffect(() => {
        const minutes =
            calculationBasis === 'actual' && selection.minutesWorked
                ? selection.minutesWorked
                : scheduledMinutes;

        setCosts(
            CostCalculator.getTotalCosts(
                selection.totalRate,
                selection.platformFeePct,
                selection.employerFeePct,
                minutes,
                calculateTotalStipend(stipends)
            )
        );
        setRates(
            CostCalculator.getCostsPerHour(
                selection.totalRate,
                selection.platformFeePct,
                selection.employerFeePct
            )
        );
    }, [
        selection.totalRate,
        selection.platformFeePct,
        selection.employerFeePct,
        calculationBasis,
    ]);

    const onChangeCalculationBasis = (val: string) => {
        setCalculationBasis(val as 'actual' | 'estimate');
    };

    const calculationOptions = [
        {
            value: 'estimate',
            label: 'Estimate',
        },
        {
            value: 'actual',
            label: `Actual`,
            disabled: !selection.minutesWorked,
        },
    ];

    return (
        <>
            <Flex justify="space-between">
                <Typography.Text strong>
                    {calculationBasis === 'actual' &&
                    selection.minutesWorked ? (
                        <>
                            Submitted Hours:{' '}
                            {roundToAtMostTwoDecimals(
                                selection.minutesWorked / 60
                            )}
                        </>
                    ) : (
                        <>
                            Scheduled Hours:{' '}
                            {(scheduledMinutes / 60).toLocaleString('US', {
                                maximumFractionDigits: 2,
                            })}
                        </>
                    )}
                </Typography.Text>
                <Segmented
                    options={calculationOptions}
                    value={calculationBasis}
                    onChange={onChangeCalculationBasis}
                />
            </Flex>
            <Typography.Title level={4}>Rates (per hour)</Typography.Title>
            <ObjectPropertyList
                values={{
                    ...{
                        platformFee: rates?.platformFeeRate,
                        employerFee: rates?.employerFeeRate,
                        grossPay: rates?.payRate,
                        totalHourlyRate: rates?.totalRate,
                    },
                }}
                fields={{
                    grossPay: usdValue,
                    platformFee: usdValue,
                    employerFee: usdValue,
                    totalHourlyRate: (rate) => (
                        <Editable
                            value={rate}
                            type={EditModeType.Money}
                            field="totalRate"
                        >
                            {usdValue(rate)}
                        </Editable>
                    ),
                }}
            />
            <Typography.Title level={4}>Fees</Typography.Title>
            <ObjectPropertyList
                values={{
                    ...{
                        platformFeeHourlyRate: rates?.platformFeeRate,
                        employerFeeHourlyRate: rates?.employerFeeRate,
                    },
                    platformFeePercent: selection.platformFeePct,
                    employerFeePercent: selection.employerFeePct,
                }}
                fields={{
                    platformFeePercent: (fee) => (
                        <Editable
                            value={fee}
                            type={EditModeType.Pct}
                            field="platformFeePct"
                        >
                            {pctValue(fee)}
                        </Editable>
                    ),
                    employerFeePercent: (fee) => (
                        <Editable
                            value={fee}
                            type={EditModeType.Pct}
                            field="employerFeePct"
                        >
                            {pctValue(fee)}
                        </Editable>
                    ),
                }}
            />
            {stipends.length > 0 ? (
                <>
                    <Typography.Title level={4}>Stipend</Typography.Title>
                    <AntList
                        bordered={true}
                        renderItem={(item) => (
                            <AntList.Item>
                                <b>{capitalize(item.stipendType)}</b>
                                {usdValue(item.amount)}
                            </AntList.Item>
                        )}
                        dataSource={selection.stipends}
                        style={{ marginBottom: 10 }}
                    />
                </>
            ) : null}
            <Typography.Title level={4}>Totals</Typography.Title>
            <ObjectPropertyList
                values={{
                    ...{
                        totalPlatformFee: costs?.platformFee,
                        totalEmployerFee: costs?.employerFee,
                        totalGrossPay: costs?.pay,
                        totalCost: costs?.total,
                    },
                }}
                fields={{
                    totalPlatformFee: usdValue,
                    totalEmployerFee: usdValue,
                    totalGrossPay: usdValue,
                    totalCost: usdValue,
                }}
            />
        </>
    );
}
