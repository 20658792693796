import { LinkOutlined } from '@ant-design/icons';
import { Space, Typography } from 'antd';
import { DateTime } from 'luxon';

import {
    AuditEntityType,
    FacilityDto,
    ShiftListItemDto,
} from '@clh/api-client';
import {
    getShiftDuration,
    getTextFromQuillDeltas,
    HumlaDateTime,
    toDateISOTimeStr,
} from '@clh/util';

import AuditHistoryButton from '../../audit-history/button';
import { getDateStrWithTimezone } from '../../helpers';
import { useSelection } from '../../hooks/use-selection';
import ObjectPropertyList from '../../record-details/object-property-list';

const Text = Typography.Text;

export default function DetailsTab() {
    const { selection } = useSelection<ShiftListItemDto>();

    const { timezone } = selection.facility.address;

    return (
        <>
            <Typography.Title level={4}>Details</Typography.Title>
            <Space direction="vertical" style={{ width: '100%' }} size="large">
                <ObjectPropertyList
                    values={{
                        ...selection,
                        hours: getShiftDuration(
                            selection.shiftTime,
                            toDateISOTimeStr(selection.startTime),
                            toDateISOTimeStr(selection.endTime)
                        ),
                    }}
                    fields={{
                        id: (id: string) => (
                            <Text code={true} copyable={true}>
                                {id}
                            </Text>
                        ),
                        facility: (facility: FacilityDto) => (
                            <>{facility.name}</>
                        ),
                        startTime: (startTime: Date) => (
                            <>{getDateStrWithTimezone(startTime, timezone)}</>
                        ),
                        endTime: (endTime: Date) => (
                            <>{getDateStrWithTimezone(endTime, timezone)}</>
                        ),
                        updatedAt: (updatedAt: Date) => (
                            <div style={{ textAlign: 'right' }}>
                                <AuditHistoryButton
                                    entityId={selection.id}
                                    entityType={AuditEntityType.Shift}
                                >
                                    {DateTime.fromJSDate(
                                        updatedAt,
                                        timezone
                                            ? { zone: timezone }
                                            : undefined
                                    ).toLocaleString(
                                        HumlaDateTime.DATETIME_SHORT
                                    )}
                                </AuditHistoryButton>
                            </div>
                        ),
                        shiftTime: true,
                        hours: true,
                        shiftType: true,
                        specialtyCode: true,
                        licenseType: true,
                        description: (description) => (
                            <>{getTextFromQuillDeltas(description)}</>
                        ),
                        stripeInvoiceId: (stripeInvoiceId: string) => (
                            <>
                                {stripeInvoiceId ? (
                                    <Space
                                        direction="vertical"
                                        size={2}
                                        style={{ width: '100%' }}
                                    >
                                        <div style={{ textAlign: 'right' }}>
                                            <Text code={true} copyable={true}>
                                                {stripeInvoiceId}
                                            </Text>
                                            <br />
                                            <a
                                                href={`https://dashboard.stripe.com/invoices/${stripeInvoiceId}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <LinkOutlined /> Open in Stripe
                                            </a>
                                        </div>
                                    </Space>
                                ) : (
                                    <Text italic>Not yet invoiced</Text>
                                )}
                            </>
                        ),
                        zealEmployeeCheckId: (zealEmployeeCheckId: string) => (
                            <>
                                {zealEmployeeCheckId ? (
                                    <Space
                                        direction="vertical"
                                        size={2}
                                        style={{ width: '100%' }}
                                    >
                                        <div style={{ textAlign: 'right' }}>
                                            <Text code={true} copyable={true}>
                                                {zealEmployeeCheckId}
                                            </Text>
                                            <br />
                                            <a
                                                href={`https://payroll.humla.com/pending-checks/check/${zealEmployeeCheckId}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <LinkOutlined /> Open in Zeal
                                            </a>
                                        </div>
                                    </Space>
                                ) : (
                                    <Text italic>Payment not yet queued</Text>
                                )}
                            </>
                        ),
                    }}
                />
            </Space>
        </>
    );
}
