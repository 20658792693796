import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Select } from '@clh/react-form-elements';
const Location = ({ onChange, states, locations, }) => {
    const [state, setState] = useState('MN');
    const [location, setLocation] = useState(null);
    useEffect(() => {
        onChange(location);
    }, [location]);
    return (_jsxs(_Fragment, { children: [_jsx(Select, { id: "state", label: "State", defaultValue: state, disabled: true, options: [
                    {
                        value: '',
                        displayValue: 'Select one',
                    },
                    ...states.map((s) => ({
                        value: s,
                    })),
                ], onChange: (e) => setState(e.target.value) }), _jsx(Select, { id: "state", label: "Metro Area", options: [
                    {
                        value: '',
                        displayValue: 'Select one',
                    },
                    ...locations
                        .filter((l) => l.state === state)
                        .map((l) => ({
                        value: l.areaName,
                    })),
                ], onChange: (e) => setLocation(e.target.value) })] }));
};
export default Location;
