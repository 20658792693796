import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Checkbox } from '@clh/react-form-elements';
const Adjustments = ({ options, onChange, }) => {
    const [adjustments, setAdjustments] = useState([]);
    useEffect(() => {
        onChange(adjustments);
    }, [JSON.stringify([...adjustments])]);
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: "mb-3", children: "Adjustments" }), options.map((a) => (_jsx(Checkbox, { id: a, value: a, label: a, onChange: (e) => {
                    const { value } = e.target;
                    if (adjustments.includes(value)) {
                        setAdjustments(adjustments.filter((a) => a !== value));
                    }
                    else {
                        setAdjustments([...adjustments, value]);
                    }
                } }, a)))] }));
};
export default Adjustments;
