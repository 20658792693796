import { jsx as _jsx } from "react/jsx-runtime";
import { Select } from '@clh/react-form-elements';
const NurseType = () => {
    return (_jsx(Select, { id: "nurse-type", label: "Nurse Type", options: [
            {
                value: 'RN',
            },
        ], disabled: true }));
};
export default NurseType;
