var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import { forwardRef } from 'react';
import InputWrapper from './input-wrapper';
var Select = forwardRef(function Input(_a, ref) {
    var afterLabel = _a.afterLabel, className = _a.className, errorMessage = _a.errorMessage, id = _a.id, label = _a.label, required = _a.required, options = _a.options, props = __rest(_a, ["afterLabel", "className", "errorMessage", "id", "label", "required", "options"]);
    return (_jsx(InputWrapper, { afterLabel: afterLabel, id: id, label: label, required: required, errorMessage: errorMessage, children: _jsx("select", __assign({ required: required, className: classNames('form-select', className), id: id, ref: ref }, props, { children: options.map(function (_a) {
                var value = _a.value, displayValue = _a.displayValue, disabled = _a.disabled, selected = _a.selected;
                return (_jsx("option", { value: value, disabled: disabled, selected: selected, children: displayValue || value }, value || displayValue));
            }) })) }));
});
export default Select;
