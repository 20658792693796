import { jsx as _jsx } from "react/jsx-runtime";
import { Select } from '@clh/react-form-elements';
const Specialty = ({ onChange, options, }) => {
    return (_jsx(Select, { id: "specialty", label: "Specialty", options: [
            {
                value: '',
                displayValue: 'Select one',
            },
            ...options.map((s) => ({
                value: s,
            })),
        ], onChange: (e) => onChange(e.target.value) }));
};
export default Specialty;
