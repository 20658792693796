import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
var embedTypes = ['application/pdf'];
var FilePreview = function (_a) {
    var file = _a.file;
    if (!file)
        return null;
    var fileUrl = URL.createObjectURL(file);
    if (file.type.includes('image')) {
        return (_jsx("div", { className: "text-center mt-2", children: _jsx("img", { className: "full-width mb-3", src: fileUrl, alt: "preview of ".concat(file.name) }) }));
    }
    if (embedTypes.includes(file.type)) {
        return (_jsx("embed", { className: "full-width min-height-sm mb-3 mt-2", src: fileUrl }));
    }
    return null;
};
export default memo(FilePreview);
