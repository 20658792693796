import { Button, ButtonProps } from 'antd';
import { PropsWithChildren, useState } from 'react';
import { createPortal } from 'react-dom';

import { AuditEntityType } from '@clh/api-client';

import AuditHistoryModal from './modal';

export default function AuditHistoryButton(
    props: ButtonProps & {
        entityId: string;
        entityType: AuditEntityType;
    } & PropsWithChildren
) {
    const [open, setOpen] = useState(false);

    const { entityId, entityType, ...buttonProps } = props;

    return (
        <>
            <Button {...buttonProps} onClick={() => setOpen(!open)} type="link">
                {props.children ?? 'History'}
            </Button>
            {open &&
                createPortal(
                    <AuditHistoryModal
                        entityId={entityId}
                        entityType={entityType}
                        onClose={() => setOpen(false)}
                    />,
                    document.body
                )}
        </>
    );
}
