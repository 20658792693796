import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import './spinner.less';
export function Spinner(_a) {
    var className = _a.className, isFullPage = _a.isFullPage;
    if (isFullPage) {
        return (_jsxs("div", { className: className, "data-testid": "spinner-full", children: [_jsx("div", { className: "spinner-overlay" }), _jsx("div", { className: "spinner-border center-page", role: "status", children: _jsx("span", { className: "visually-hidden", children: "Loading..." }) })] }));
    }
    return (_jsx("div", { className: classNames('mb-3 d-flex justify-content-center', className, {
            'mt-5': !(className === null || className === void 0 ? void 0 : className.includes('mt-')),
        }), "data-testid": "spinner-inline", children: _jsx("div", { className: "spinner-border text-primary", role: "status", children: _jsx("span", { className: "visually-hidden", children: "Loading..." }) }) }));
}
