import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
const YearsOfExperience = ({ onChange, }) => {
    const [num, setNum] = useState(1);
    useEffect(() => {
        onChange(num);
    }, [num]);
    return (_jsxs(_Fragment, { children: [_jsx("label", { htmlFor: "experience", className: "form-label", children: "Years of Experience" }), _jsxs("div", { className: "d-flex", children: [_jsx("input", { style: { minWidth: 100 }, type: "range", className: "form-range me-2", id: "experience", value: num, max: 30, min: 0, onChange: (e) => setNum(+e.target.value) }), _jsx("input", { type: "number", value: num, onChange: (e) => setNum(+e.target.value) })] })] }));
};
export default YearsOfExperience;
