import { blue, gray, green, red } from '@ant-design/colors';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Badge, Collapse, List, Row } from 'antd';
import isBoolean from 'lodash/isBoolean';
import startCase from 'lodash/startCase';
import { DateTime } from 'luxon';

import { HumlaDateTime } from '@clh/util';

/**
 * Infer basic formatting for a javascript primitive with +1 depth introspection for arrays/objects
 *
 * @param props - value to display
 *
 * @returns JSX.Element
 */
export default function DisplayValue({
    value,
    nested = true,
}: {
    value: any;
    nested?: boolean;
}) {
    if (isScalar(value)) {
        return <ScalarValue value={value} />;
    }

    if (Array.isArray(value)) {
        return <ArrayValue values={value} collapseValues={!!nested} />;
    }

    if (typeof value === 'object') {
        return (
            <Row>
                <ObjectValue value={value} />
            </Row>
        );
    }

    return <>{value}</>;
}

function isScalar(value: any) {
    return (
        value === null ||
        typeof value !== 'object' ||
        value instanceof Date ||
        isBoolean(value)
    );
}

const ObjectValue = ({ value }: { value: object }) => {
    return (
        <table>
            <tbody>
                {Object.keys(value).map((key) => (
                    <tr key={key}>
                        <th>{startCase(key)}</th>
                        <td>
                            <ScalarValue
                                value={(value as { [k in string]: any })[key]}
                            />
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

const ScalarValue = ({ value }: { value: any }) => {
    const isoDateRegex =
        /^\d{4}-\d{2}-\d{2}(T\d{2}:\d{2}:\d{2}(\.\d+)?(Z|[+-]\d{2}:\d{2})?)?$/;

    if (typeof value === 'string' && isoDateRegex.test(value)) {
        value = new Date(value);
    }

    if (value instanceof Date) {
        return (
            <>
                {DateTime.fromJSDate(value).toLocaleString(
                    HumlaDateTime.DATETIME_MED
                )}
            </>
        );
    }

    if (isBoolean(value)) {
        return value ? (
            <CheckCircleOutlined style={{ color: green.primary }} />
        ) : (
            <CloseCircleOutlined style={{ color: red.primary }} />
        );
    }

    if (value === null || value === undefined) {
        return <i>N/A</i>;
    }

    return <>{value}</>;
};

const ArrayValue = ({
    values,
    collapseValues = true,
}: {
    values: any[];
    collapseValues: boolean;
}) => {
    if (values.length === 0 || !collapseValues) {
        return (
            <Badge
                count={values.length}
                style={{ backgroundColor: gray.primary }}
            />
        );
    }

    if (values.length === 1) {
        return <DisplayValue value={values[0]} nested={false} />;
    }

    return (
        <Collapse bordered={false}>
            <Collapse.Panel
                header={
                    <Badge
                        count={values.length || 0}
                        style={{ backgroundColor: blue.primary }}
                    />
                }
                key="1"
            >
                {isScalar(values[0]) && <ArrayAsList values={values} />}
            </Collapse.Panel>
        </Collapse>
    );
};

const ArrayAsList = ({ values }: { values: any[] }) => {
    return (
        <List>
            {values.map((val) => (
                <List.Item key={val}>
                    <DisplayValue value={val} nested={false} />
                </List.Item>
            ))}
        </List>
    );
};
