import { Typography } from 'antd';
import { DateTime } from 'luxon';

import {
    AuditEntityType,
    ListProfileDto,
    NurseProfileCompletenessDto,
    PayrollStatus,
    Roles,
} from '@clh/api-client';
import { HumlaDateTime } from '@clh/util';

import { ActionButton } from '../../action-button';
import AuditHistoryButton from '../../audit-history/button';
import { DisplayValue } from '../../display-value';
import { useApiClient } from '../../hooks/use-api-client';
import { useSelection } from '../../hooks/use-selection';
import RecordDetails from '../../record-details/record-details';

const Text = Typography.Text;

const { ENVIRONMENT_NAME } = ENV_CONFIG_VARS;

const payrollStatus = (status: PayrollStatus) => (
    <div>
        {
            {
                [PayrollStatus.OnboardingCompleted]: (
                    <>
                        <b>Completed</b> <br />
                        Nurse is fully onboarded and will receive payment
                    </>
                ),
                [PayrollStatus.OnboardingStarted]: (
                    <>
                        <b>Incomplete</b> <br />
                        Nurse has started but not completed payroll onboarding
                    </>
                ),
                [PayrollStatus.Eligible]: (
                    <>
                        <b>Eligible</b> <br />
                        Nurse is eligible, but has not started payroll
                        onboarding
                    </>
                ),
                [PayrollStatus.Ineligible]: (
                    <>
                        <b>Not currently eligible</b> <br />
                        Nurse does not have a verified license in their profile
                    </>
                ),
                [PayrollStatus.InvalidAddress]: (
                    <>
                        <b>Invalid address</b> <br />
                        The address entered for this nurse's profile could not
                        be validated as a real address
                    </>
                ),
            }[status]
        }
    </div>
);

export default function ProfileTab() {
    const api = useApiClient();
    const { selection } = useSelection<ListProfileDto>();

    const nurseProfileCompleteness = (
        completeness: NurseProfileCompletenessDto
    ) => {
        if (!completeness) {
            return <>N/A</>;
        }

        const canFastTrack =
            !Object.values(completeness).every(Boolean) &&
            api &&
            ENVIRONMENT_NAME !== 'production';

        return (
            <div>
                <DisplayValue value={completeness} />
                {canFastTrack && (
                    <ActionButton
                        successMessage="Profile fast tracked"
                        action={async () =>
                            await api.adminControllerFastTrackProfile({
                                profileId: selection.id,
                            })
                        }
                    >
                        Fast Track
                    </ActionButton>
                )}
            </div>
        );
    };

    if (!api) {
        return null;
    }

    return (
        <RecordDetails
            fields={{
                firstName: true,
                lastName: true,
                id: (id) => (
                    <Text code={true} copyable={true}>
                        {id}
                    </Text>
                ),
                email: (email) => <Text copyable={true}>{email}</Text>,
                phoneNumber: (val) => <>{val?.number || ''}</>,
                ncsbnId: (ncsbnId) =>
                    ncsbnId ? <Text copyable={true}>{ncsbnId}</Text> : <></>,
                roles: true,
                permissions: true,
                nurseProfileCompleteness,
                payrollStatus,
                updatedAt: (updatedAt: Date) => (
                    <div style={{ textAlign: 'right' }}>
                        <AuditHistoryButton
                            entityId={selection.id}
                            entityType={AuditEntityType.Profile}
                        >
                            {DateTime.fromJSDate(updatedAt).toLocaleString(
                                HumlaDateTime.DATETIME_SHORT
                            )}
                        </AuditHistoryButton>
                    </div>
                ),
                isBlocked: (isBlocked) =>
                    isBlocked ? (
                        <ActionButton
                            action={async () => {
                                await api?.accountControllerUnblockAccount({
                                    profileId: selection.id,
                                });
                            }}
                            successMessage="User Reenabled"
                        >
                            Reenable Profile
                        </ActionButton>
                    ) : (
                        <ActionButton
                            action={async () => {
                                await api?.accountControllerBlockAccount({
                                    profileId: selection.id,
                                });
                            }}
                            successMessage="User Disabled"
                        >
                            Disable Profile
                        </ActionButton>
                    ),
            }}
            getRecord={async () => {
                const [
                    profile,
                    nurseProfileCompleteness,
                    { roles },
                    { permissions },
                    { avatar },
                ] = await Promise.all([
                    api.profileControllerGetProfile({
                        profileId: selection.id,
                    }),
                    selection.roles.includes(Roles.Nurse)
                        ? api.nurseControllerGetProfileCompleteness({
                              profileId: selection.id,
                          })
                        : null,
                    api.profileControllerGetUserRoles({
                        profileId: selection.id,
                    }),
                    api.profileControllerGetUserPermissions({
                        profileId: selection.id,
                    }),
                    api
                        .profileControllerGetAvatar({
                            profileId: selection.id,
                        })
                        .catch(() => ({
                            avatar: null,
                        })),
                ]);

                return {
                    ...profile,
                    avatar,
                    roles,
                    permissions,
                    nurseProfileCompleteness,
                };
            }}
            cacheKey={'profileControllerGetProfile' + selection.id}
        />
    );
}
