import { AutoCompleteProps, AutoComplete, Spin } from 'antd';
import debounce from 'lodash/debounce';
import { useMemo, useRef, useState } from 'react';

import { NurseProfileDto } from '@clh/api-client';
import { unwrapError, useApiClient } from '@clh/ui';

import { useToast } from '../hooks/use-toast';

const ProfileAutoComplete = (props: {
    onSelect: (profile: NurseProfileDto) => void;
}) => {
    const [options, setOptions] = useState<AutoCompleteProps['options']>([]);
    const api = useApiClient();
    const { notification } = useToast();

    const [fetching, setFetching] = useState(false);
    const fetchRef = useRef(0);

    const debouncedSearch = useMemo(() => {
        const loadOptions = (query: string) => {
            fetchRef.current += 1;
            const fetchId = fetchRef.current;
            setOptions([]);
            setFetching(true);
            api
                ?.profileControllerListProfiles({
                    search: query,
                })
                .then((profiles) => {
                    if (fetchId !== fetchRef.current) {
                        return;
                    }
                    setOptions(
                        profiles.map((profile) => ({
                            value: `${profile.firstName} ${profile.lastName}`,
                            label: `${profile.firstName} ${profile.lastName}`,
                            id: profile.id,
                            firstName: profile.firstName,
                            lastName: profile.lastName,
                        }))
                    );

                    setFetching(false);
                })
                .catch((err) => {
                    notification.error({
                        message: unwrapError(err),
                        duration: 3,
                    });
                });
        };

        return debounce(loadOptions, 800);
    }, [api]);

    return (
        <AutoComplete
            autoFocus={true}
            options={options}
            onSearch={(query: string) => debouncedSearch(query)}
            onSelect={(value, option) =>
                props.onSelect({
                    id: option.id,
                    firstName: option.firstName,
                    lastName: option.lastName,
                })
            }
            style={{ width: 200 }}
            notFoundContent={fetching ? <Spin size="small" /> : null}
        ></AutoComplete>
    );
};

export default ProfileAutoComplete;
