var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import Label from './label';
function InputWrapper(_a) {
    var afterLabel = _a.afterLabel, _b = _a.className, className = _b === void 0 ? 'mb-3' : _b, helpText = _a.helpText, hideLabel = _a.hideLabel, id = _a.id, label = _a.label, required = _a.required, errorMessage = _a.errorMessage, children = _a.children, props = __rest(_a, ["afterLabel", "className", "helpText", "hideLabel", "id", "label", "required", "errorMessage", "children"]);
    return (_jsxs("div", { className: className, children: [_jsxs("div", { className: classNames({
                    'd-flex justify-content-between mb-2': afterLabel,
                }), children: [_jsx(Label, __assign({ className: classNames({
                            'visually-hidden': hideLabel,
                            'mb-0': afterLabel,
                        }), helpText: helpText, htmlFor: id, required: required }, props, { children: label })), afterLabel] }), children, errorMessage && (_jsx("div", { className: "invalid-feedback", children: errorMessage }))] }));
}
export default InputWrapper;
