import { FileAddOutlined, PaperClipOutlined } from '@ant-design/icons';
import { Input, Space, List, Flex, Button, Upload, UploadFile } from 'antd';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';

import { FacilityNoteDto, ProfileNoteDto } from '@clh/api-client';
import { useApiClient } from '@clh/ui';
import { HumlaDateTime } from '@clh/util';

import { ActionButton } from '../action-button';

const NoteListItem = ({
    item,
    isEditing,
    isAuthor,
    fetchNotes,
    setIsEditing,
}: {
    item: ProfileNoteDto | FacilityNoteDto;
    isEditing: boolean;
    isAuthor: boolean;
    fetchNotes: () => Promise<(ProfileNoteDto | FacilityNoteDto)[]>;
    setIsEditing: (bool: boolean) => void;
}) => {
    const api = useApiClient();
    const [isLoading, setIsLoading] = useState(false);
    const [editNote, setEditNote] = useState(item.note);
    const [removeFiles, setRemoveFiles] = useState<UploadFile[]>([]);
    const [addFiles, setAddFiles] = useState<UploadFile[]>([]);
    const [editNoteFileList, setEditNoteFileList] = useState<UploadFile[]>([]);

    useEffect(() => {
        setEditNoteFileList(
            item.attachments.map((attachment) => ({
                uid: attachment.id,
                url: attachment.url,
                name: attachment.fileName,
            }))
        );
        setAddFiles([]);
        setRemoveFiles([]);
    }, [item]);

    return (
        <List.Item>
            <Space direction="vertical" style={{ width: '100%' }}>
                <Flex justify="space-between">
                    <div>
                        <b>{item.author}</b>
                    </div>
                    <div style={{ textAlign: 'right' }}>
                        {item.createdAt.toLocaleDateString()}
                        {item.createdAt.getTime() < item.updatedAt.getTime() ? (
                            <>
                                <br />
                                <i>
                                    (edited at{' '}
                                    {DateTime.fromJSDate(
                                        item.updatedAt
                                    ).toLocaleString(
                                        HumlaDateTime.DATETIME_SHORT
                                    )}
                                    )
                                </i>
                            </>
                        ) : null}
                    </div>
                </Flex>

                {isEditing ? (
                    <>
                        <Input.TextArea
                            defaultValue={item.note}
                            autoSize={true}
                            autoFocus={true}
                            disabled={isLoading}
                            onChange={(evt) => {
                                setEditNote(evt.target.value);
                            }}
                        />
                        <div>
                            <ActionButton
                                size="small"
                                successMessage="Note updated"
                                action={async () => {
                                    if (!api) {
                                        return;
                                    }
                                    setIsLoading(true);
                                    await api.noteControllerUpdateNote({
                                        noteId: item.id,
                                        profileNoteCreateDto: {
                                            note: editNote,
                                        },
                                    });

                                    if (addFiles.length > 0) {
                                        await api?.noteControllerUploadAttachments(
                                            {
                                                noteId: item.id,
                                                attachments:
                                                    addFiles as unknown as File[],
                                            }
                                        );
                                    }

                                    if (removeFiles.length > 0) {
                                        await Promise.all(
                                            removeFiles.map(
                                                async (file) =>
                                                    api?.noteControllerDeleteAttachment(
                                                        {
                                                            noteId: item.id,
                                                            attachmentId:
                                                                file.uid,
                                                        }
                                                    )
                                            )
                                        );
                                    }

                                    await fetchNotes();

                                    setIsEditing(false);
                                    setIsLoading(false);
                                }}
                            >
                                Save
                            </ActionButton>{' '}
                            <Button
                                size="small"
                                onClick={() => setIsEditing(false)}
                            >
                                Cancel
                            </Button>
                        </div>
                    </>
                ) : (
                    <p style={{ whiteSpace: 'pre-wrap' }}>{item.note}</p>
                )}
                {item.attachments.length > 0 && (
                    <>
                        {isEditing ? (
                            <Upload
                                onRemove={(file: UploadFile) => {
                                    const index =
                                        editNoteFileList.indexOf(file);

                                    const newFileList =
                                        editNoteFileList.slice();
                                    newFileList.splice(index, 1);
                                    setEditNoteFileList(newFileList);

                                    setRemoveFiles([...removeFiles, file]);
                                }}
                                beforeUpload={(file) => {
                                    setEditNoteFileList([
                                        ...editNoteFileList,
                                        file,
                                    ]);

                                    setAddFiles([...addFiles, file]);

                                    return false;
                                }}
                                fileList={editNoteFileList}
                            >
                                <Button icon={<FileAddOutlined />} size="small">
                                    Attach File
                                </Button>
                            </Upload>
                        ) : (
                            item.attachments.map((attachment) => (
                                <div key={attachment.id}>
                                    <PaperClipOutlined
                                        style={{ marginRight: 5 }}
                                    />
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href={attachment.url}
                                    >
                                        {attachment.fileName}
                                    </a>
                                </div>
                            ))
                        )}
                    </>
                )}

                {isAuthor && !isEditing && (
                    <div>
                        <a role="button" onClick={() => setIsEditing(true)}>
                            Edit
                        </a>
                        <ActionButton
                            successMessage="Note deleted"
                            type="link"
                            confirm="Are you sure you want to delete this note?"
                            action={async () => {
                                await api?.noteControllerDeleteNote({
                                    noteId: item.id,
                                });
                                await fetchNotes();
                            }}
                        >
                            Delete
                        </ActionButton>
                    </div>
                )}
            </Space>
        </List.Item>
    );
};

export default NoteListItem;
