var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import Quill from 'quill';
import { forwardRef, useEffect, } from 'react';
import InputWrapper from './input-wrapper';
import 'quill/dist/quill.snow.css';
import './editor.less';
var Editor = forwardRef(function Input(_a, ref) {
    var id = _a.id, label = _a.label, required = _a.required, errorMessage = _a.errorMessage, onChange = _a.onChange, initialValue = _a.initialValue, wasValidated = _a.wasValidated, props = __rest(_a, ["id", "label", "required", "errorMessage", "onChange", "initialValue", "wasValidated"]);
    useEffect(function () {
        var q = new Quill("#editor-".concat(id), {
            modules: {
                toolbar: [
                    ['bold', 'italic', 'underline'],
                    [{ list: 'ordered' }, { list: 'bullet' }],
                    ['link'],
                ],
            },
            theme: 'snow',
        });
        if (initialValue) {
            if (typeof initialValue === 'string') {
                q.setContents({
                    ops: [
                        {
                            insert: initialValue,
                        },
                    ],
                });
            }
            else {
                q.setContents(initialValue);
            }
        }
        q.on('text-change', function () {
            if (onChange) {
                onChange({
                    target: {
                        name: id,
                        value: q.getContents().ops,
                    },
                });
            }
        });
    }, []);
    var isInvalid = !!errorMessage;
    return (_jsx(InputWrapper, { label: label, id: id, errorMessage: errorMessage, required: required, children: _jsx("div", __assign({}, props, { className: classNames('editor form-control rounded-0 border-top', {
                'border-danger is-invalid': isInvalid,
                'is-valid border-success': wasValidated && !isInvalid,
            }), id: "editor-".concat(id), ref: ref })) }));
});
export default Editor;
