import { ArrowRightOutlined } from '@ant-design/icons';
import { Button, Empty, Flex, Modal, Steps, Table } from 'antd';
import Sider from 'antd/es/layout/Sider';
import startCase from 'lodash/startCase';
import { useEffect, useState } from 'react';

import { AuditEntityType, AuditLogDto } from '@clh/api-client';
import { unwrapError, useApiClient } from '@clh/ui';
import { getTextFromQuillDeltas, toDollarAmount } from '@clh/util';

import { DisplayValue } from '../display-value';
import { useToast } from '../hooks/use-toast';

function friendlyValue(field: string, value: any) {
    if (!value) {
        return value;
    }
    if (field === 'payRate' || field === 'totalRate') {
        return toDollarAmount(value);
    }
    if (field === 'description') {
        try {
            return getTextFromQuillDeltas(value);
        } catch (_) {
            // Oh well
        }
    }

    return value;
}

function DiffTable({
    current,
    previous,
}: {
    current?: Record<string, any>;
    previous?: Record<string, any>;
}) {
    if (!current || !previous) {
        return null;
    }

    const fields = new Set([...Object.keys(current), ...Object.keys(previous)]);

    const dataSource = Array.from(fields)
        .filter((field) => previous[field] || current[field])
        .map((field) => ({
            field: startCase(field),
            previous: (
                <DisplayValue value={friendlyValue(field, previous[field])} />
            ),
            current: (
                <DisplayValue value={friendlyValue(field, current[field])} />
            ),
            _: (
                <>
                    <ArrowRightOutlined />
                </>
            ),
        }));

    return (
        <Table
            bordered
            columns={[
                { title: 'Field', dataIndex: 'field' },
                { title: 'Previous', dataIndex: 'previous' },
                { title: '', dataIndex: '_' },
                { title: 'Current', dataIndex: 'current' },
            ]}
            dataSource={dataSource}
            pagination={false}
        ></Table>
    );
}

export default function AuditHistoryModal({
    entityId,
    entityType,
    onClose,
}: {
    entityId: string;
    entityType: AuditEntityType;
    onClose?: () => void;
}) {
    const api = useApiClient();
    const [open, setOpen] = useState(true);
    const [loading, setLoading] = useState(false);
    const [history, setHistory] = useState<AuditLogDto[]>();

    const [selected, setSelected] = useState(0);

    const { notification } = useToast();
    const fetchAuditHistory = async () => {
        setLoading(true);
        try {
            const results = await api?.auditControllerGetHistory({
                entityId,
                entityType,
            });
            setHistory(results);
        } catch (err) {
            notification.error({ message: unwrapError(err), duration: 3 });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        void fetchAuditHistory();
    }, [api]);

    const current = JSON.parse(history?.[selected]?.current || '{}');
    const previous = JSON.parse(history?.[selected]?.previous || '{}');

    return (
        <Modal
            title={`${entityType} Audit History`}
            centered
            loading={loading}
            width={'60%'}
            open={open}
            onCancel={() => setOpen(false)}
            onClose={() => setOpen(false)}
            afterClose={() => onClose && onClose()}
            footer={[
                <Button key="back" onClick={() => setOpen(false)}>
                    Close
                </Button>,
            ]}
        >
            {history && history.length > 0 ? (
                <Flex
                    justify="space-around"
                    align="flex-start"
                    style={{ paddingTop: '20px' }}
                >
                    <Sider
                        width={300}
                        style={{
                            overflow: 'auto',
                            height: '80vh',
                            insetInlineStart: 0,
                            scrollbarWidth: 'thin',
                            scrollbarGutter: 'stable',
                        }}
                    >
                        <Steps
                            progressDot
                            current={selected}
                            size="small"
                            onChange={(ix) => setSelected(ix)}
                            direction="vertical"
                            items={
                                history?.map((log, ix) => ({
                                    title: (
                                        <DisplayValue value={log.createdAt} />
                                    ),
                                    status:
                                        selected === ix
                                            ? 'process'
                                            : selected < ix
                                            ? 'finish'
                                            : 'wait',
                                    description: log.changedByProfile ? (
                                        <>
                                            {log.changedByProfile.firstName}{' '}
                                            {log.changedByProfile.lastName}
                                        </>
                                    ) : (
                                        log.changedByAuth0UserId
                                    ),
                                })) || []
                            }
                        />
                    </Sider>
                    <Flex justify="center" style={{ width: '60%' }}>
                        <DiffTable current={current} previous={previous} />
                    </Flex>
                </Flex>
            ) : (
                <Empty />
            )}
        </Modal>
    );
}
