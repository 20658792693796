import {
    CalendarOutlined,
    ContactsOutlined,
    GlobalOutlined,
    LogoutOutlined,
    EnvironmentOutlined,
    FileTextOutlined,
    DatabaseOutlined,
    LockOutlined,
    DollarOutlined,
    CalculatorOutlined,
} from '@ant-design/icons';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';

const items = [
    {
        key: 'nav-profiles',
        icon: <ContactsOutlined />,
        label: <Link to={`/profiles`}>Profiles</Link>,
    },
    {
        key: 'nav-facilities',
        icon: <GlobalOutlined />,
        label: <Link to={`/facilities`}>Facilities</Link>,
    },
    {
        key: 'nav-shifts',
        icon: <CalendarOutlined />,
        label: <Link to={`/shifts`}>Shifts</Link>,
    },
    {
        key: 'nav-jurisdictions',
        icon: <EnvironmentOutlined />,
        label: <Link to={`/jurisdictions`}>Jurisdictions</Link>,
    },
    {
        key: 'nav-compacts',
        icon: <FileTextOutlined />,
        label: <Link to={`/compacts`}>Compacts</Link>,
    },
    {
        key: 'platform-admins',
        icon: <LockOutlined />,
        label: <Link to={`/platform-admins`}>Platform Admins</Link>,
    },
    {
        key: 'nav-payroll',
        icon: <DollarOutlined />,
        label: <Link to={`/payroll`}>Payroll</Link>,
    },
    {
        key: 'nav-rate-calculator',
        icon: <CalculatorOutlined />,
        label: <Link to={`/rate-calculator`}>Rate Calculator</Link>,
    },
];

if (ENV_CONFIG_VARS.ENVIRONMENT_NAME !== 'prod') {
    items.push({
        key: 'test-data',
        icon: <DatabaseOutlined />,
        label: <Link to={`/test-data`}>Test Data</Link>,
    });
}

items.push({
    key: 'nav-logout',
    icon: <LogoutOutlined />,
    label: <Link to={`/logout`}>Logout</Link>,
});

export const Nav = () => {
    return <Menu theme="light" mode="inline" items={items} />;
};
