// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ql-toolbar {
  border-bottom: 0 !important;
}
.ql-tooltip {
  z-index: 1;
}
.editor.is-invalid {
  border-top: 1 !important;
}
.ql-hidden {
  display: none;
}
`, "",{"version":3,"sources":["webpack://./../../../packages/react-form-elements/dist/editor.less"],"names":[],"mappings":"AAAA;EACI,2BAAA;AACJ;AAEA;EACI,UAAA;AAAJ;AAGA;EACI,wBAAA;AADJ;AAIA;EACI,aAAA;AAFJ","sourcesContent":[".ql-toolbar {\n    border-bottom: 0 !important;\n}\n\n.ql-tooltip {\n    z-index: 1;\n}\n\n.editor.is-invalid {\n    border-top: 1 !important;\n}\n\n.ql-hidden {\n    display: none;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
