var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { forwardRef } from 'react';
var CheckBox = forwardRef(function Input(_a, ref) {
    var className = _a.className, labelClassName = _a.labelClassName, id = _a.id, label = _a.label, props = __rest(_a, ["className", "labelClassName", "id", "label"]);
    return (_jsxs("div", { className: classNames('form-check', {
            'mb-3': !(className === null || className === void 0 ? void 0 : className.includes('mb-')),
        }, className), children: [_jsx("input", __assign({ className: "form-check-input", type: "checkbox", value: "", id: id, ref: ref }, props)), _jsx("label", { className: classNames('form-check-label', labelClassName), htmlFor: id, children: label })] }));
});
export default CheckBox;
