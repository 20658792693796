import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Alert, Spinner, useApiClient, useError } from '@clh/ui';
import Adjustments from './adjustments';
import CalculateStipend from './calculate-stipend';
import Location from './location';
import NurseType from './nurse-type';
import RateSummary from './rate-summary';
import Specialty from './specialty';
import YearsOfExperience from './years-of-experience';
const Section = ({ children }) => {
    return _jsx("div", { className: "card p-3 mb-3", children: children });
};
const RateCalculator = ({ facilityId, platformFeePct: platformFeePctFromProps = 0.07, employerFeePct = 0.1, onError, isPlatformAdminView, }) => {
    const api = useApiClient();
    const [platformFeePct, setPlatformFeePct] = useState(platformFeePctFromProps);
    const [isOptionsLoading, setIsOptionsLoading] = useState(true);
    const [options, setOptions] = useState({
        adjustments: [],
        states: [],
        locations: [],
        sectorCategories: [],
        sectors: [],
        specialties: [],
    });
    const [location, setLocation] = useState('MN');
    const [experience, setExperience] = useState(1);
    const [specialty, setSpecialty] = useState(null);
    const [adjustments, setAdjustments] = useState([]);
    const [rates, setRates] = useState({
        employerFee: 0,
        nurseTakeHome: 0,
        platformFee: 0,
        totalCost: 0,
        totalCostUpperRange: 0,
        totalCostLowerRange: 0,
    });
    const [isRatesLoading, setIsRatesLoading] = useState(true);
    const [error, setError] = useError();
    const onPlatformFeeChange = (pct) => {
        setPlatformFeePct(pct);
    };
    useEffect(() => {
        if (api === null || api === void 0 ? void 0 : api.isAuthenticated) {
            void api
                .rateCalculatorControllerGetOptions()
                .then(setOptions)
                .catch(onError)
                .finally(() => {
                setIsOptionsLoading(false);
            });
        }
    }, [api === null || api === void 0 ? void 0 : api.isAuthenticated]);
    useEffect(() => {
        api === null || api === void 0 ? void 0 : api.rateCalculatorControllerGetEstimatedRate({
            location: location || undefined,
            experience: experience || undefined,
            specialty: specialty || undefined,
            platformFeePercentage: facilityId ? undefined : platformFeePct,
            adjustments,
            facilityId,
        }).then(setRates).catch(setError).finally(() => {
            setIsRatesLoading(false);
        });
    }, [
        location,
        experience,
        specialty,
        JSON.stringify(adjustments),
        platformFeePct,
        facilityId,
    ]);
    useEffect(() => {
        setPlatformFeePct(platformFeePctFromProps);
    }, [platformFeePctFromProps]);
    if (isOptionsLoading) {
        return _jsx(Spinner, {});
    }
    if (error) {
        return _jsx(Alert, { message: error });
    }
    return (_jsxs("div", { className: "row", children: [_jsxs("div", { className: "col", children: [_jsx(Section, { children: _jsx(NurseType, {}) }), _jsx(Section, { children: _jsx(YearsOfExperience, { onChange: setExperience }) }), _jsx(Section, { children: _jsx(Location, { states: options === null || options === void 0 ? void 0 : options.states, locations: options === null || options === void 0 ? void 0 : options.locations, onChange: setLocation }) }), _jsx(Section, { children: _jsx(Specialty, { onChange: setSpecialty, options: options.specialties }) }), _jsx(Section, { children: _jsx(Adjustments, { onChange: setAdjustments, options: options.adjustments }) })] }), _jsx("div", { className: "col", children: _jsxs("div", { className: "position-sticky", style: { top: 16 }, children: [_jsx(RateSummary, { employerFeePct: employerFeePct, onPlatformFeeChange: onPlatformFeeChange, isLoading: isRatesLoading, rates: rates, platformFeePct: platformFeePct, isPlatformAdminView: isPlatformAdminView }), _jsx(CalculateStipend, {})] }) })] }));
};
export default RateCalculator;
