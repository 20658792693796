import { Tooltip as BsTooltip } from 'bootstrap';
import { cloneElement, isValidElement, useEffect, useRef, } from 'react';
import { createRoot } from 'react-dom/client';
export var Tooltip = function (props) {
    var childRef = useRef(undefined);
    useEffect(function () {
        var _a;
        var title = isValidElement(props.title)
            ? convertJsxToDomElement(props.title)
            : props.title;
        var t = new BsTooltip(childRef.current, {
            title: title,
            placement: (_a = props.placement) !== null && _a !== void 0 ? _a : 'top',
            trigger: 'hover',
            html: true,
        });
        return function () { return t.dispose(); };
    }, [props.title, props.placement]);
    return cloneElement(props.children, { ref: childRef });
};
var convertJsxToDomElement = function (jsx) {
    var container = document.createElement('div');
    var root = createRoot(container);
    root.render(jsx);
    return container;
};
